import React from 'react';
import { useLocation } from 'react-router-dom';
import './protected.css';

const SiteDownPage = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const txid = query.get('txid');

  return (
    <div className='bodyfr'>
      <div className="containerfr">
        <div className="textfr">
          Your payment has been completed, please check discord for further details.
        </div>
        {txid && (
          <div className="txid">
            Transaction ID: {txid}
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteDownPage;