import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PaymentCompleted from './PaymentCompleted';
import PaymentDuplicate from './PaymentDuplicate';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/payment-completed" element={<PaymentCompleted />} />
        <Route path="/payment-duplicated" element={<PaymentDuplicate />} />
          
      </Routes>
    </Router>
  );
}

export default App;